import React, {Component} from 'react';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaLinkedin} from "react-icons/fa"

import './join.scss'

class Join extends Component {
    render() {
        return (
            <div className="join-container">
                <div className="container">
                    <div className="join-block">
                        <div className="join-title">
                            <h3>Join our team to help shape the future of <br /> Content & Solutions for the Automotive Industry.</h3>
                        </div>
                        <div className="join-email">
                            <p>Share your CV at <a href='mailto:jobs@amarela.io'>jobs@amarela.io</a></p>
                        </div>
                        <div className="find-more" >
                            <p>Find out more at</p>
                            <div className="social-links-block">
                                <a href="https://www.facebook.com/amarela.io"><FaFacebookSquare className="icon facebook-icon" /></a>
                                <a href="https://www.linkedin.com/company/35528493/admin/"><FaLinkedin className="icon linkedin-icon" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Join;
