import React from "react"
import "./developer-pack.scss"
import Join from "../../main/join/join"
import Slider from "react-slick"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const DeveloperPack = () => (
  <StaticQuery query={graphql`
      query {
        img1: file(relativePath: { eq: "slider/1.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img2: file(relativePath: { eq: "slider/2.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img3: file(relativePath: { eq: "slider/3.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img4: file(relativePath: { eq: "slider/4.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img5: file(relativePath: { eq: "slider/5.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img6: file(relativePath: { eq: "slider/6.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img7: file(relativePath: { eq: "slider/7.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img8: file(relativePath: { eq: "slider/8.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img9: file(relativePath: { eq: "slider/9.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        img10: file(relativePath: { eq: "slider/10.jpeg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

      }
    `}
               render={data => {

                 const settings = {
                   dots: true,
                   infinite: true,
                   speed: 500,
                   slidesToShow: 3,
                   slidesToScroll: 1,
                   variableWidth: true,
                 }

                 return (
                   <div className="developer-pack-container">
                     <div className="container">
                       <div className="developer-pack-block">
                         <div className="title">
                           <div className="line"></div>
                           <h2>Developer Starter Pack</h2>
                           <div className="line"></div>
                         </div>
                         <div className="media-mobile">
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img1.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img2.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img3.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img4.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img5.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img6.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img7.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img9.childImageSharp.fluid}
                           ></BackgroundImage>
                           <BackgroundImage Tag="div"
                                            className="slider-image"
                                            fluid={data.img10.childImageSharp.fluid}
                           ></BackgroundImage>
                         </div>
                         <div className="slider">
                           <Slider {...settings}>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img1.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img2.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img3.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img4.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img5.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img6.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img7.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img9.childImageSharp.fluid}
                             ></BackgroundImage>
                             <BackgroundImage Tag="div"
                                              className="slider-image"
                                              fluid={data.img10.childImageSharp.fluid}
                             ></BackgroundImage>

                           </Slider>
                         </div>

                         <div className="sub-title">
                           <div className="text">We are <span className="hiring">Hiring</span></div>

                           <a href="https://www.facebook.com/amarela.io" rel="noopener noreferrer" target="_blank" className="open-link">View open
                             positions</a>
                         </div>
                         <Join/>
                       </div>
                     </div>
                   </div>
                 )
               }
               }
  />
)

export default DeveloperPack
