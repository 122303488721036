import React from "react"

import "./main-reason.scss"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Slider from "react-slick";

const MainReason = () => (

  <StaticQuery query={graphql`
      query {
        office1: file(relativePath: { eq: "Archive/office1.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        digital1: file(relativePath: { eq: "Archive/digital1.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        digital2: file(relativePath: { eq: "Archive/digital2.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        digital3: file(relativePath: { eq: "Archive/digital3.png" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        office2: file(relativePath: { eq: "Archive/office2.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        office3: file(relativePath: { eq: "Archive/office3.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        office4: file(relativePath: { eq: "Archive/office4.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        office5: file(relativePath: { eq: "Archive/office5.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }


        office7: file(relativePath: { eq: "Archive/office7.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        office8: file(relativePath: { eq: "Archive/office8.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
     render={data => {

       const settings = {
         slidesToShow: 3,
         slidesToScroll: 1,
         infinite: true,
         centerMode: true,
         centerPadding: '0px',
         variableWidth: false,
         variableHeight: true,
         arrows: false,
         dots: true,
         autoplay: true,
         autoplaySpeed: 1500,
         pauseOnHover: true

       };

       return (
         <div className="join-container-main-reason">
           <div className="main-layer">
            <div className="container">
               <div className="page-title">
                 <span>Work with us</span>
               </div>
               <div className="motto-text">
                 <span>Join our team to help shape the future of Transportation and Automotive Industry</span>
               </div>
               <div className="join-block">
                 <div className="join-title">
                   <h3>Join our team to help shape the future of Content & Solutions for the Automotive
                     Industry.</h3>
                 </div>
                 <div className="join-email">
                   Share your CV at <a href="mailto:jobs@amarela.io">jobs@amarela.io</a>
                 </div>
               </div>
             </div>
           </div>
             <div className="container">
               <div className="media-show" >
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.digital2.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.office1.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.digital3.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.office2.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.office3.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.office4.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.office5.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.office7.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.digital1.childImageSharp.fluid}
                 ></BackgroundImage>
                 <BackgroundImage Tag="div"
                                  className="office-image"
                                  fluid={data.office8.childImageSharp.fluid}
                 ></BackgroundImage>
               </div>
               <div className="media">
                 <Slider {...settings}>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.digital1.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.office1.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.digital2.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.office2.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.office3.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.office4.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.office5.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.digital3.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.office7.childImageSharp.fluid}
                   ></BackgroundImage>
                   <BackgroundImage Tag="div"
                                    className="office-image"
                                    fluid={data.office8.childImageSharp.fluid}
                   ></BackgroundImage>
                 </Slider>
               </div>
             </div>
         </div>
       )
     }
     }
  />
)

export default MainReason
