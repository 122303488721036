import React from "react"
import "./why-join.scss"
import png1 from "../../../images/icons/svg1.svg"
import png2 from "../../../images/icons/svg2.svg"
import png3 from "../../../images/icons/svg3.svg"
import png4 from "../../../images/icons/svg4.svg"
import png5 from "../../../images/icons/svg5.svg"
import png6 from "../../../images/icons/svg6.svg"
import png7 from "../../../images/icons/svg7.svg"
import ListItem from "./list-item/list-item"

const WhyJoin = () => {
  const itemsContent = [
    {
      id: 1,
      imageUrl: png1,
      content: 'A talented team of dedicated people, involved in full-scale decisions with great impact '
    },
    {
      id: 2,
      imageUrl: png2,
      content: 'Stay healthy: private medical insurance'
    },
    {
      id: 3,
      imageUrl: png3,
      content: 'Life-long learning: professional development courses'
    },
    {
      id: 4,
      imageUrl: png4,
      content: 'Comfortable work location in the center of the city\n' +
        'and last, but not least'
    },
    {
      id: 5,
      imageUrl: png5,
      content: 'Seductive salary: your skills and dedication are valued'
    },
    {
      id: 6,
      imageUrl: png6,
      content: 'A full-time job like no other with time off for holidays, travelling and recreation'
    },
    {
      id: 7,
      imageUrl: png7,
      content: 'Serve yourself: catered meals, snacks & beverages'
    }

  ];
  return (
    <div className="why-join-container">
      <div className="container">
        <div className="leadership-block">
          <div className="title">
            <h2>Why join us?</h2>
          </div>
          <div className="info">
            <span className="why-reason">To guarantee our team can do their best work consistently without agonizing over anything, we
              make a point to give them the best advantages in the business:</span>

          </div>
          <div className="cards-container">
            {
              itemsContent.map((data) => {
                return <ListItem key={data.id} cardContent={data}/>
              })
            }
          </div>
          <div className="info">
            <span className="at-last">
              and last, but not least...
            </span>
            <span className="decide">
              You decide on the tools and devices!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyJoin;
