import React, {Component} from 'react';

import './list-item.scss'

class ListItem extends Component {
    render() {
        const {cardContent} = this.props;
        return (
            <div className="card-items-block">
                <div className="icon-block">
                  <img src={cardContent.imageUrl} alt="card" className="card-image"/>
                </div>
                <div className="card-text">
                  <span>{cardContent.content}</span>
                </div>
            </div>
        );
    }
}

export default ListItem;
