import React from "react"

import Layout from "../components/layout"
import MainReason from "../components/work-with-us-components/main-reason/main-reason"
import WhyJoin from "../components/work-with-us-components/why-join/why-join"
import DeveloperPack from "../components/work-with-us-components/developer-pack/developer-pack"
import { Helmet } from "react-helmet/es/Helmet"
import favicon from "../images/favicons/favicon.ico"


const WorkWithUsPage = () => (
  <Layout>
    <Helmet>
      <title>Amarela - Content & Solutions for Automotive Industry</title>
      <meta name="title" content="Amarela - Content & Solutions for Automotive Industry"/>
      <meta name="description" content="Amarela is a leading provider of solutions and data for autonomous driving, located in Paris and Chisinau."/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://metatags.io/"/>
      <meta property="og:title" content="Amarela - Content & Solutions for Automotive Industry"/>
      <meta property="og:description" content="Amarela is a leading provider of solutions and data for autonomous driving, located in Paris and Chisinau."/>
      <meta property="og:image" content="https://static.md/9706d715358d89d8a26a3ed840e45688.png"/>
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="https://metatags.io/"/>
      <meta property="twitter:title" content="Amarela - Content & Solutions for Automotive Industry"/>
      <meta property="twitter:description" content="Amarela is a leading provider of solutions and data for autonomous driving, located in Paris and Chisinau."/>
      <meta property="twitter:image" content="https://static.md/9706d715358d89d8a26a3ed840e45688.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href={favicon}/>
      <link rel="icon" type="image/png" sizes="16x16" href={favicon}/>

    </Helmet>
    <MainReason/>
    <WhyJoin />
    <DeveloperPack />
  </Layout>
)

export default WorkWithUsPage
